import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./Navbar.css";

const NavbarItem = (p) => {
  return (
    <li className="nav-item">
      <Link to={p.to} className="nav-item-links">
        {p.item}
      </Link>
    </li>
  );
};

const Navbar = () => {
  const critMobile = () => window.innerWidth < 950;
  const [isMobile, setMobile] = useState(critMobile());
  const updMobile = () => setMobile(critMobile());
  window.addEventListener("resize", updMobile);

  return (
    <nav className="nav a-fade-in">
      <Link to="/" className="nav-logo">
        Noah Lykins
        <span class="material-symbols-outlined">arrow_upward</span>
      </Link>
      {isMobile ? (
        <></>
      ) : (
        <ul className="nav-items">
          {/* <NavbarItem item={"Projects"} to={"/Projects"} /> */}
          <NavbarItem item={"Resume"} to={"/Resume"} />
        </ul>
      )}
    </nav>
  );
};

export default Navbar;

import { Navbar, Footer, Resume, Home } from "./components";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import "./lib/animations.css";

function App() {
  return (
    <div className="app">
      <Router>
        <Navbar />
        <div className="main">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/resume" element={<Resume />} />
          </Routes>
        </div>
        {/* <Footer /> */}
      </Router>
    </div>
  );
}

export default App;

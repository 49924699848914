import React from "react";
import "./Hero.css";

const Hero = () => {
  const updMouse = (e) => {
    document.body.style.setProperty(
      "--mouse-x",
      e.clientX / document.body.offsetWidth
    );
    document.body.style.setProperty(
      "--mouse-y",
      e.clientY / document.body.offsetHeight
    );
  };
  document.body.style.setProperty("--mouse-x", 0.4);
  document.body.style.setProperty("--mouse-y", 0.4);
  window.addEventListener("mousemove", updMouse);

  return (
    <div className="hero">
      <div className="hero-content a-fade-in">
        <h1 className>Hello;</h1>
        <p>Software and data solutions</p>
        {/* <p className="hero-csoon">Coming Soon</p> */}
      </div>
      <div className="hero-img a-fade-in">
        <img src="../media/pic1.jpg" alt="" />
      </div>
    </div>
  );
};

export default Hero;

import React from "react";
import "./Resume.css";

const Resume = () => {
  return (
    <div className="resume">
      <embed src="../media/Resume Noah Lykins.pdf" />
    </div>
  );
};

export default Resume;

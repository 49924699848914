import React from "react";
import "./Home.css";
import { Hero } from "../../components";

const Home = () => {
  return (
    <div className="home">
      <Hero />
    </div>
  );
};

export default Home;
